import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38c9aa12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "-list-main orderlist"
}
const _hoisted_2 = {
  key: 0,
  class: "-list-bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return ($setup.list)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createVNode(_component_el_table, {
          class: "-list-table -scroll-x",
          data: $setup.list.data,
          "max-height": $setup.setting.tableHeight,
          onSelectionChange: _ctx._listSelectionChange,
          ref: "_listTable",
          stripe: "",
          "header-cell-class-name": "-list-th",
          "cell-class-name": "-list-td",
          "highlight-current-row": true,
          "show-overflow-tooltip": true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              type: $setup.setting.tableFirstType,
              fixed: "",
              width: $setup.setting.tableFirstWidth,
              label: $setup.setting.tableFirstLabel
            }, null, 8, ["type", "width", "label"]),
            _createVNode(_component_el_table_column, {
              prop: "orderNo",
              label: "订单编号"
            }),
            _createVNode(_component_el_table_column, {
              prop: "orderType",
              label: "订单类型"
            }),
            _createVNode(_component_el_table_column, {
              prop: "title",
              label: "订单描述"
            }),
            _createVNode(_component_el_table_column, {
              prop: "totalBuy",
              label: "购买数量"
            }),
            _createVNode(_component_el_table_column, {
              prop: "totalPayFee",
              label: "订单金额"
            }),
            _createVNode(_component_el_table_column, {
              prop: "payType",
              label: "支付方式"
            }),
            _createVNode(_component_el_table_column, {
              prop: "payTime",
              label: "支付时间"
            }),
            _createVNode(_component_el_table_column, {
              prop: "addTime",
              label: "账单时间",
              "min-width": "100"
            }),
            _createVNode(_component_el_table_column, {
              prop: "sMchState",
              label: "账单状态"
            })
          ]),
          _: 1
        }, 8, ["data", "max-height", "onSelectionChange"]), [
          [_directive_loading, $setup.list.searching]
        ]),
        ($setup.setting.openBottom)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_el_pagination, {
                "page-size": $setup.list.size,
                total: $setup.list.total,
                onSizeChange: _ctx._listChangeSize,
                onCurrentChange: _ctx._listChangeIndex,
                "current-page": $setup.list.index,
                "page-sizes": [10, 15, 20, 30, 50, 100],
                "pager-count": "6",
                layout: "total, sizes, prev, pager, next, jumper"
              }, null, 8, ["page-size", "total", "onSizeChange", "onCurrentChange", "current-page"])
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}